<template>
	<a-row :gutter="8">
		<!--业务字典树-->
		<a-col :span="5">
			<q-tree-container>
				<template #select>
					<a-tree-select
						class="left"
						@change="handleChange"
						tree-default-expand-all
						style="width: 100%"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="CompanyId"
					/>
				</template>

				<template #tree>
					<a-tree
						:treeData="PostTreeData"
						:selectedKeys="[postId]"
						showIcon
						defaultExpandAll
						@select="onSelect"
					>
						<a-icon type="folder" slot="folder" />
						<a-icon type="file" slot="file" />
						<a-icon type="folder-open" slot="folder-open" />
						<template slot="custom" slot-scope="{ expanded }">
							<a-icon :type="expanded ? 'folder-open' : 'folder'" />
						</template>
					</a-tree>
				</template>
			</q-tree-container>
		</a-col>

		<a-col :span="19">
			<!-- usePagination开启分页 -->
			<!-- loading绑定引入页面的蒙层加载变量 -->
			<q-container usePagination :loading="loading">
				<!-- 使用具名插槽slot="table" -->
				<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
				<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

				<!-- **************行选择*************** -->
				<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
				<!-- 行选择默认单选，多选需要再data重写变量isMultiple 并赋值true -->
				<template slot="table" slot-scope="scopeParam">
					<a-table
						ref="table"
						size="small"
						:pagination="false"
						:columns="columns"
						:rowKey="(tableDatas) => tableDatas.id"
						:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
						:dataSource="tableData"
					>
					</a-table>
				</template>
				<!-- 使用具名插槽 #page -->
				<template #page>
					<!-- 分页组件只需复制以下代码 -->
					<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
					<a-pagination
						v-model="pageNumber"
						:total="totalItems"
						showSizeChanger
						size="small"
						:defaultPageSize="request.maxResultCount"
						:pageSizeOptions="pageSizeOptions"
						:showTotal="() => showTotal"
						@change="onPageChange"
						@showSizeChange="showPageSizeChange"
					/>
				</template>
			</q-container>
		</a-col>
	</a-row>
</template>

<script>
import { ModalComponentBase } from '@/shared/component-base';
import { ModalHelper } from '@/shared/helpers';
import {
	OrgPostServiceProxy,
	DepartmentServiceProxy,
	OrgPostGradeServiceProxy,
	UserRelationServiceProxy,
	CompanyServiceProxy,
	DataItemDetailServiceProxy,
} from '../../../shared/service-proxies';
import Adduserrelation from '../userrelation/userrelation-add-user-panel/add-userrelation.vue';
import * as _ from 'lodash';
import moment from 'moment';
import { abpService } from '../../../shared/abp';

export default {
	name: 'userrelation',
	mixins: [ModalComponentBase],
	components: { Adduserrelation },
	data() {
		return {
			//字典树
			treeData: [],
			// 用户表格
			columns: [
				{
					title: '工号',
					dataIndex: 'jobNumber',
					ellipsis: true,
					sorter: false,
					align: 'center',
					fixed: 'left',
					width: 100,
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: '姓名',
					dataIndex: 'realName',
					ellipsis: true,
					sorter: false,
					align: 'center',
					fixed: 'left',
					width: 100,
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: '账号',
					dataIndex: 'userName',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'userName' },
				},
				{
					title: '性别',
					dataIndex: 'genderName',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'genderName' },
				},
				{
					title: '手机',
					dataIndex: 'mobile',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'mobile' },
				},
				{
					title: '部门',
					dataIndex: 'displayName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'displayName' },
				},
				{
					title: '岗位',
					dataIndex: 'orgName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'orgName' },
				},
				{
					title: '生日',
					dataIndex: 'birthday',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'birthday' },
				},
				{
					title: '年龄',
					dataIndex: 'age',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'age' },
				},
				{
					title: '司龄',
					dataIndex: 'companyAge',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'companyAge' },
					width: 150,
				},
				{
					title: '邮箱',
					dataIndex: 'personalEmail',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'personalEmail' },
					width: 250,
				},
				{
					title: '入职日期',
					dataIndex: 'hireDate',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'hireDate' },
				},
				{
					title: '试用期',
					dataIndex: 'probationMonth',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'probationMonth' },
				},
				{
					title: '试用期职等',
					dataIndex: 'probationGradeName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'probationGradeName' },
				},
				{
					title: '转正后职等',
					dataIndex: 'regularGradeName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'regularGradeName' },
				},
				{
					title: '转正日期',
					dataIndex: 'regularDate',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'regularDate' },
				},
				{
					title: '首次工作日期',
					dataIndex: 'firstWorkDate',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'firstWorkDate' },
				},
				{
					title: '民族',
					dataIndex: 'nationName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'nationName' },
				},
				{
					title: '籍贯',
					dataIndex: 'nativePlace',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'nativePlace' },
				},
				{
					title: '户口性质',
					dataIndex: 'residenceName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'residenceName' },
				},
				{
					title: '户籍地址',
					dataIndex: 'identityAddress',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'identityAddress' },
				},
				{
					title: '常住地址',
					dataIndex: 'presentAddress',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'presentAddress' },
				},
				{
					title: '常住地址邮编',
					dataIndex: 'postalCode',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'postalCode' },
				},
				{
					title: '身份证号',
					dataIndex: 'identityCard',
					ellipsis: true,
					align: 'center',
					width: 250,
					scopedSlots: { customRender: 'identityCard' },
				},
				{
					title: '证件开始日期',
					dataIndex: 'identityStartDate',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'identityStartDate' },
				},
				{
					title: '身份证有效期',
					dataIndex: 'identityValidity',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'identityValidity' },
				},
				{
					title: '员工种类一',
					dataIndex: 'empKindOne',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'empKindOne' },
				},
				{
					title: '员工种类二',
					dataIndex: 'empKindTwo',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'empKindTwo' },
				},
				{
					title: '员工种类三',
					dataIndex: 'empKindThree',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'empKindThree' },
				},
				{
					title: '员工类型',
					dataIndex: 'empTypeName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'empTypeName' },
				},
				{
					title: '员工状态',
					dataIndex: 'empStateName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'empStateName' },
				},
				{
					title: '婚姻状况',
					dataIndex: 'maritalStatusName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'maritalStatusName' },
				},
				{
					title: '政治面貌',
					dataIndex: 'politicsStatusName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'politicsStatusName' },
				},
				{
					title: '学历',
					dataIndex: 'educationsName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'educationsName' },
				},
				{
					title: '招聘来源',
					dataIndex: 'recruitmentSourceName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'recruitmentSourceName' },
				},
				{
					title: '劳务公司',
					dataIndex: 'personalServiceName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'personalServiceName' },
				},
				{
					title: '银行卡号',
					dataIndex: 'cardNo',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'cardNo' },
				},
				{
					title: '开户行名称',
					dataIndex: 'bankName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'bankName' },
				},
				{
					title: '社保编号',
					dataIndex: 'socialSecurity',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'socialSecurity' },
				},
				{
					title: '公积金编号',
					dataIndex: 'reservedFunds',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'reservedFunds' },
				},
				{
					title: '离职日期',
					dataIndex: 'termDate',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'termDate' },
				},
			],
			tableData: [],
			//选中的树节点ID
			CompanyId: undefined,
			depmentId: null,
			treespinning: false,
			//部门树
			PostTreeData: [],
			PostTree: [],
			//部门Id集合
			companyTreeList: [],
			companyList: [],
			postId: undefined,
		};
	},
	created() {
		this._companyServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._OrgPostServiceProxy = new OrgPostServiceProxy(this.$apiUrl, this.$api);
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._dataitemDetailServiceProxy = new DataItemDetailServiceProxy(this.$apiUrl, this.$api);
		this._orgPostGradeServiceProxy = new OrgPostGradeServiceProxy(this.$apiUrl, this.$api);
		this._userRelationServiceProxy = new UserRelationServiceProxy(this.$apiUrl, this.$api);
		this.GetCompany();
		if (abpService.abp.userInfo.companyId) {
			this.CompanyId = abpService.abp.userInfo.companyId;
			this.handleChange(this.CompanyId);
		}
	},
	methods: {
		/**
		 * 选中树结构
		 */
		getTree(data) {
			this.selectTree = data;
			this.getData();
		},
		/**
		 * 获取公司列表
		 */
		GetCompany() {
			this._companyServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.treespinning = false;
				})
				.then((res) => {
					if (res.items.length < 1) return;
					if (res.items.length > 0 && !this.CompanyId) {
						this.CompanyId = res.items[0].id;
						this.getPostTreeData();
					}
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
				});
		},
		/**
		 * 公司初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		handleChange(value) {
			this.postId = undefined;
			this.CompanyId = value;
			this.getPostTreeData();
		},
		/**
		 * 点击树
		 */
		onSelect(keys, event) {
			this.postId = keys[0];
			this.getData();
			this.request.skipCount = 0;
			this.pageNumber = 1;
		},
		/**
		 * 获取岗位员
		 */
		getData() {
			this.loading = true;
			this.selectedRowObj = {};

			this._OrgPostServiceProxy
				.getEmpPageByPost(
					this.CompanyId,
					this.postId,
					this.filterText,
					'id',
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = [];
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.checked = false;
						item.birthday = item.birthday ? moment(item.birthday).format('YYYY-MM-DD') : '';
						item.creationTime = item.creationTime ? moment(item.creationTime).format('YYYY-MM-DD') : '';
						item.hireDate = item.hireDate ? moment(item.hireDate).format('YYYY-MM-DD') : '';
						item.regularDate = item.regularDate ? moment(item.regularDate).format('YYYY-MM-DD') : '';
						item.termDate = item.termDate ? moment(item.termDate).format('YYYY-MM-DD') : '';
						item.firstWorkDate = item.firstWorkDate ? moment(item.firstWorkDate).format('YYYY-MM-DD') : '';
						item.identityValidity = item.identityValidity
							? moment(item.identityValidity).format('YYYY-MM-DD')
							: '';
						item.identityStartDate = item.identityStartDate
							? moment(item.identityStartDate).format('YYYY-MM-DD')
							: '';
					});
					this.totalItems = res.totalCount;
					this.pagerange = [
						(this.pageNumber - 1) * this.request.maxResultCount + 1,
						this.pageNumber * this.request.maxResultCount,
					];
					this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
				});
		},
		/**
		 * 设置岗位树数据
		 */
		getPostTreeData() {
			this.PostTreeData = [];
			this._OrgPostServiceProxy
				.getPagedDepPosts(
					this.CompanyId == undefined ? 999 : this.CompanyId,
					this.depmentId == undefined ? undefined : this.depmentId,
					'',
					undefined,
					999,
					0
				)
				.then((res) => {
					this.PostTree = res.items;
					this.PostTree.forEach((item) => {
						if (item.parentId == 0) {
							let obj = {
								key: item.id,
								value: item.id.toString(),
								title: item.name,
							};
							this.PostTreeData.push(obj);
						}
					});
					this.initPostTree(this.PostTreeData);
					this.getData();
				});
		},
		/**
		 * 岗位初始化部门树形结构
		 */
		initPostTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.PostTree.forEach((m) => {
					if (m.parentId == v.key) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id.toString(),
							title: m.name,
						};
						v.children.push(obj); //放进父级的集合中
						this.initPostTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		/**
		 * 添加
		 */
		addUser() {
			ModalHelper.create(Adduserrelation, {
				organizationUnitId: undefined,
				companyId: this.companyId,
			}).subscribe((res) => {
				if (res) {
					var that = this;
					this.treespinning = true;
					const input = new AddPostUsersDot();
					var ids = _.map(res, 'id');
					input.orgPostId = that.postId;
					input.depUserIds = ids;
					this._userRelationServiceProxy
						.addPostUsers(input)
						.finally(() => {
							this.treespinning = false;
						})
						.then((res) => {
							this.$notification['success']({
								message: this.l('SavedSuccessfully'),
							});
							this.onaSelect();
						});
				}
			});
		},

		/**
		 * 批量删除
		 */
		batchDepUserDelete() {
			if (this.selectedRowKeys.length <= 0) {
				abp.message.warn(this.l('PleaseSelectAtLeastOneItem'));
				return;
			}
			this.$confirm({
				content: this.l('ConfirmDeleteXItemsWarningMessage', this.selectedRowKeys.length),
				cancelText: this.l('Cancel'),
				okText: this.l('Ok'),
				onOk: () => {
					this.treespinning = true;
					var ids = _.map(this.selectedRowKeys);
					this._userRelationServiceProxy
						.batchDeleteUsers(ids)
						.finally(() => {
							this.treespinning = false;
						})
						.then((res) => {
							this.pageNumber = 1;
							this.request.skipCount = 0;
							this.$notification['success']({
								message: this.l('SuccessfullyDeleted'),
							});
							this.getData();
						});
				},
			});
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
